@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("Inter (web)/Inter-Thin-BETA.woff2") format("woff2"),
       url("Inter (web)/Inter-Thin-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("Inter (web)/Inter-ThinItalic-BETA.woff2") format("woff2"),
       url("Inter (web)/Inter-ThinItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("Inter (web)/Inter-ExtraLight-BETA.woff2") format("woff2"),
       url("Inter (web)/Inter-ExtraLight-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("Inter (web)/Inter-ExtraLightItalic-BETA.woff2") format("woff2"),
       url("Inter (web)/Inter-ExtraLightItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("Inter (web)/Inter-Light-BETA.woff2") format("woff2"),
       url("Inter (web)/Inter-Light-BETA.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("Inter (web)/Inter-LightItalic-BETA.woff2") format("woff2"),
       url("Inter (web)/Inter-LightItalic-BETA.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("Inter (web)/Inter-Regular.woff2") format("woff2"),
       url("Inter (web)/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("Inter (web)/Inter-Italic.woff2") format("woff2"),
       url("Inter (web)/Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("Inter (web)/Inter-Medium.woff2") format("woff2"),
       url("Inter (web)/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("Inter (web)/Inter-MediumItalic.woff2") format("woff2"),
       url("Inter (web)/Inter-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("Inter (web)/Inter-SemiBold.woff2") format("woff2"),
       url("Inter (web)/Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("Inter (web)/Inter-SemiBoldItalic.woff2") format("woff2"),
       url("Inter (web)/Inter-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("Inter (web)/Inter-Bold.woff2") format("woff2"),
       url("Inter (web)/Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("Inter (web)/Inter-BoldItalic.woff2") format("woff2"),
       url("Inter (web)/Inter-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("Inter (web)/Inter-ExtraBold.woff2") format("woff2"),
       url("Inter (web)/Inter-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("Inter (web)/Inter-ExtraBoldItalic.woff2") format("woff2"),
       url("Inter (web)/Inter-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("Inter (web)/Inter-Black.woff2") format("woff2"),
       url("Inter (web)/Inter-Black.woff") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("Inter (web)/Inter-BlackItalic.woff2") format("woff2"),
       url("Inter (web)/Inter-BlackItalic.woff") format("woff");
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url("Inter (web)/Inter-roman.var.woff2") format("woff2");
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url("Inter (web)/Inter-italic.var.woff2") format("woff2");
}


/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url("Inter (web)/Inter.var.woff2") format("woff2");
}
